<template>
  <b-card no-body class="card-custom m-5 p-5 w-lg-550px" header-tag="header">
    <!--begin::Header-->
    <b-card-header class="card-header px-2">
      <div class="card-title align-items-start flex-column">
        <h2 class="card-label font-weight-bolder text-dark">
          SmartId Account Sign In
        </h2>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Please sign into SmartId account</span
        >
      </div>
    </b-card-header>
    <!--end::Header-->
    <b-card-body class="d-flex flex-column">
      <h6 v-if="login === 0" class="mb-10 text-dark">
        If you have a <strong>SMARTID</strong> account, please
        <strong>sign-in</strong> to verify you as the registered employee.
      </h6>

      <!--begin:: Login Form-->
      <b-form v-if="login === 0" ref="employee-verification-form">
        <!--begin::Body-->

        <b-form-group label-cols-lg="2" label-cols-xl="2" label="Email">
          <b-form-input
            class="form-control form-control-solid py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            size="lg"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
            placeholder="Email address"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Password" label-cols-lg="2" label-cols-xl="2">
          <b-form-input
            class="form-control form-control-solid py-5 px-6"
            type="password"
            size="lg"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
            placeholder="Password"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <!--end::Body-->
      </b-form>
      <!--end:: Login Form-->

      <!-- begin:: Verification Form -->
      <EmployeeVerificationAfterLoginForm v-if="login === 1">
      </EmployeeVerificationAfterLoginForm>

      <!-- end:: Verification Form -->
    </b-card-body>
    <b-card-footer footer-class="ml-auto mt-auto border-0">
      <b-button
        ref="submit-button"
        class="mr-2"
        type="submit"
        variant="primary"
        v-on:click="registerEmployee"
        v-if="login === 0"
      >
        Verify
      </b-button>
      <b-button
        class="mr-2"
        variant="light"
        v-on:click="cancelLogin"
        v-if="login === 0"
      >
        Cancel
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import swal from "sweetalert";

import EmployeeVerificationAfterLoginForm from "@/modules/auth/components/verification/EmployeeVerificationAfterLoginForm";

export default {
  name: "EmployeeVerificationLoginForm",
  components: { EmployeeVerificationAfterLoginForm },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  beforeMount() {
    if (this.isLoggedIn) this.$store.dispatch("completeLoginPhase");
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    registerEmployee() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["submit-button"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch("login", this.form)
        .then(() => {
          swal({
            title: "Login Successful",
            text: "Please verify and confirm.",
            icon: "success",
          });
          this.$store.dispatch("completeLoginPhase");
        })
        .catch((error) => {
          // Handles other than validation errors.
          if (!error.response.data.errors)
            swal("Registration Failed", "Server Error detected.", "error");
          this.$bvToast.toast("Error", {
            title: "BootstrapVue Toast",
            variant: "danger",
            toastClass: "m-5",
          });
        })
        .finally(() =>
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          )
        );
    },
    cancelLogin() {
      this.$router.push({
        path: "/verification/employee",
        query: { code: this.code },
      });
    },
  },
  computed: {
    ...mapState({
      code: (state) =>
        state.AuthModule.EmployeeVerification.VerificationInit.verificationCode,
    }),
    ...mapGetters({
      login: "getVerificationPhaseLogin",
      isLoggedIn: "isAuthenticated",
    }),
    errors() {
      return this.$store.state.Auth.errors;
    },
  },
};
</script>

<style scoped></style>
