<template>
  <div>
    <p>Use this account?</p>
    <p>Email: {{ profile.email }}</p>
    <b-button
      variant="primary"
      v-bind:class="{ 'spinner spinner-light spinner-right': isBusy === true }"
      block
      @click="complete"
      >Verify</b-button
    >
  </div>
</template>

<script>
import { mapState } from "vuex";
import swal from "sweetalert";

export default {
  name: "EmployeeVerificationAfterLoginForm",
  data() {
    return {
      isBusy: false,
    };
  },
  beforeMount() {
    this.$store.dispatch("fetchAuthProfile");
  },
  methods: {
    complete() {
      this.isBusy = true;
      this.$store
        .dispatch("completeVerificationPhase")
        .then(() => {
          swal({
            title: "Registration successful",
            text: "Please Login using email and password",
            icon: "success",
          });
          this.$router.push("dashboard");
        })
        .finally(() => (this.isBusy = false));
    },
  },
  computed: {
    ...mapState({
      profile: (state) => state.AuthModule.AuthProfile.profile,
    }),
  },
};
</script>

<style scoped></style>
